import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";

function getProcessNames() {
  return baseService.get(API_INTERFACE_URI.REPROCESS_PATH, globalHeaders);
}
function getFailedEvents(queryParams) {
  let url = API_INTERFACE_URI.FAILED_EVENTS_PATH;
  if (queryParams != undefined) url = url + "?" + queryParams;
  return baseService.get(url, globalHeaders);
}

function retryEvents(payload) {
  return baseService.post(
    API_INTERFACE_URI.RETRY_EVENTS_PATH,
    globalHeaders,
    payload
  );
}

function getBulkFailedEvents(payload) {
  let url = API_INTERFACE_URI.BULK_FAILED_EVENTS_PATH;
  return baseService.post(url, globalHeaders, payload);
}
function bulkRetryEvents(payload) {
  return baseService.post(
    API_INTERFACE_URI.BULK_RETRY_EVENTS_PATH,
    globalHeaders,
    payload
  );
}

export const ReprocessService = {
  getProcessNames,
  getFailedEvents,
  retryEvents,
  getBulkFailedEvents,
  bulkRetryEvents,
};
