import "./App.css";
import React, { useEffect, useState } from "react";
import { AegisClient, useAuth, LoginRequired } from "@nike/aegis-auth-react";
import { Button, PageSpinner } from "@nike/epic-react-ui";
import config from "../config/config.js";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Route, Switch } from "react-router-dom";
import ConsolPlan from "../containers/ConsolPlan.js";
import ProposedPlan from "../containers/ProposedPlan.js";
import ViewPreviousPlan from "../containers/ViewPreviousPlan.js";
import NotFoundPage from "./NotFoundPage.js";
import { setGlobalHeader } from "../utilities/http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
const client = new AegisClient({
  qa: !config.isProd,
  ...config.oAuth,
});

function App() {
  const history = useHistory();

  const { loginComplete, isLoggedIn, clearLogin } = useAuth(client);
  const [userToken, setUserToken] = useState();
  const [displayLogout, setDisplayLogout] = useState(true);
  useEffect(() => {
    function handleLogin({ accessToken }) {
      setGlobalHeader("Authorization", `Bearer ${accessToken.accessToken}`);
      setUserToken(accessToken.accessToken);
      setDisplayLogout(true);
    }

    client.on("login:success", handleLogin);
    client.on("renew:access:success", handleLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loginComplete || !userToken) return <PageSpinner show={true} />;
  if (!isLoggedIn) return <LoggedOut />;

  function handleLogout() {
    setGlobalHeader("Authorization", null);
    clearLogin();
    client.logout();
    setDisplayLogout(false);
    history.push("/logout");
  }

  return (
    <div className="container-fluid">
      <Header handleLogout={handleLogout} displayLogout={displayLogout} />
      <div className="component-main">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <Route exact path="/" component={ConsolPlan}></Route>
          <Route path="/proposedplan" component={ProposedPlan}></Route>
          <Route path="/plan/:planId" component={ViewPreviousPlan}></Route>
          <Route path="/logout" component={LoggedOut}></Route>
          <Route component={NotFoundPage}></Route>
        </Switch>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

function LoggedOut() {
  return (
    <div style={{ margin: "50px" }}>
      <div style={{ marginBottom: "20px" }}>
        <span className="epic-body-text-1 table-label">
          You have been logged out from application.
        </span>
        <p>
          <span className="epic-body-text-1 table-label">
            Close the browser to log out from Okta session.
          </span>
        </p>
      </div>
      <Button onClick={(event) => (window.location.href = "/")}>Login</Button>
    </div>
  );
}
export default App;
