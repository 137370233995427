import React, { useEffect, useState } from "react";
import "@nike/epic-react-ui/dist/styles/main.css";
import "../styles/proposeplan.css";
import "../styles/appstyle.css";
import { Button, Table, IconButton, PageSpinner } from "@nike/epic-react-ui";
import { useHistory } from "react-router-dom";
import { ParameterService } from "../services/service.parameters";
import { PlanningService } from "../services/service.planning";
import { toast } from "react-toastify";
import XDC_ERROR_MESSAGES from "../error/error";

const ViewPreviousPlan = (props) => {
  const history = useHistory();
  const [dcParameter, setDcParameter] = useState([]);
  const [carrierParameter, setCarrierParameter] = useState([]);
  const [planDay, setPlanDay] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  let planDetails = props.location.state.planDetails;
  //hook to get the current shipping point parameter settings
  useEffect(async () => {
    let shippingParamIds = Array.prototype.map
      .call(planDetails.planShippingParametrs, function (item) {
        return item.shippingParameterId;
      })
      .join(",");
    let dcQueryParams = `filters=shippingParamId(${shippingParamIds})`;
    let response = await ParameterService.getDcParameters(dcQueryParams);
    response.data.objects.map((dc) => {
      if (dc.state === 1) dc.state = "Enable";
      else dc.state = "Disable";
    });
    setDcParameter(response.data.objects);
  }, []);

  //hook to find the day of the week to load carrier cut-off times based on plan date
  useEffect(() => {
    let dayOfWeek = planDetails.carrierDay;
    if (dayOfWeek === "SUNDAY") setPlanDay("cutOffCarrierSun");
    if (dayOfWeek === "MONDAY") setPlanDay("cutOffCarrierMon");
    if (dayOfWeek === "TUESDAY") setPlanDay("cutOffCarrierTue");
    if (dayOfWeek === "WEDNESDAY") setPlanDay("cutOffCarrierWed");
    if (dayOfWeek === "THURSDAY") setPlanDay("cutOffCarrierThu");
    if (dayOfWeek === "FRIDAY") setPlanDay("cutOffCarrierFri");
    if (dayOfWeek === "SATURDAY") setPlanDay("cutOffCarrierSat");
  }, []);

  //hook to get the current carrier parameter settings
  useEffect(async () => {
    let carrierParamIds = Array.prototype.map
      .call(planDetails.planCarrierParametrs, function (item) {
        return item.carrierParameterId;
      })
      .join(",");
    let carrierQueryParams = `filters=carrierParamId(${carrierParamIds})`;
    let response = await ParameterService.getCarrierParameters(
      carrierQueryParams
    );
    response.data.objects.map((carrier) => {
      if (carrier.state === 1) carrier.state = "Enable";
      else carrier.state = "Disable";
    });
    setCarrierParameter(prepareResponse(response).data.objects);
  }, []);

  function prepareResponse(response) {
    let result = {};
    result.data = {};
    let objects = response.data.objects;
    objects.map(function (e) {
      let times = e.cutOffTimes;
      times.map(function (t) {
        if (t.day.toUpperCase() === "SUNDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierSun = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "MONDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierMon = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "TUESDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierTue = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "WEDNESDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierWed = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "THURSDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierThu = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "FRIDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierFri = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "SATURDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierSat = t.cutoffTime.substring(0, 5);
      });
    });
    result.data.objects = objects;
    return result;
  }

  //function to go back to plan list.
  const executePlan = async () => {
    history.push("/");
  };

  //function to download the  to plan list.
  const downloadPlanDetails = async () => {
    setShowSpinner(true);
    await PlanningService.getPlanDetailsById(planDetails.planSequenceId)
        .then((response) => {
          let element = document.createElement('a');
          element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data));
          element.setAttribute('download', planDetails.planSequenceId);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          setShowSpinner(false);
        })
        .catch((response) => {
          setShowSpinner(false);
          toast.error(XDC_ERROR_MESSAGES.DOWNLOAD_PLANNED_DNS, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
  };

  return (
    <div className="div-align-center">
      <div className="proposed-plan-root-div bg-color">
        <div className="proposed-div-padding">
          <span
            className="epic-title-3 table-label"
            test-id="propose-plan-title"
            style={{width: '90%', display: 'inline-block'}}
          >
            Plan Details
          </span>
          <span
              className="epic-title-3 table-label"
              test-id="propose-plan-title"
          >
            <IconButton
                test-id="plan-details-download-icon"
                iconClassName="preview-icon"
                buttonClassName="preview-icon"
                bare={true}
                extraSmall={true}
                type="Download"
                iconSize="l"
                onClick={downloadPlanDetails}
            />
          </span>
        </div>
        <div className="proposed-section-div-position">
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="plan-id-sub-title"
            >
              Plan ID:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="plan-id-value">
              <b>{planDetails.planSequenceId}</b>
            </span>
          </div>
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="plan-cutoff-time-sub-title"
            >
              Planning Cut-Off Time:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="plan-cutoff-time-value">
              <b>{planDetails.planCutOffTime.substring(0, 16)}</b>
            </span>
          </div>
        </div>

        <div className="proposed-section-div-position">
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="req-units-sub-title"
            >
              Requested Consolidation Units:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="req-units-value">
              <b>{planDetails.requestedUnits}</b>
            </span>
          </div>
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="proposed-units-sub-title"
            >
              Proposed Consolidation Units:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="proposed-units-value">
              <b>{planDetails.plannedUnits}</b>
            </span>
          </div>
        </div>

        <div className="proposed-section-div-position">
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <div>
              <span
                className="epic-body-text-1 sub-label"
                test-id="non-sort-threshold-sub-title"
              >
                Non-Sortables Threshold:
              </span>
            </div>
          </div>
          <div style={{ width: "28%" }}>
            <div>
              <span className="epic-body-text-1" test-id="non-sort-value">
                <b>{planDetails.requestedNonSortUnits}</b>
              </span>
            </div>
          </div>
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <div>
              <span
                className="epic-body-text-1 sub-label"
                test-id="planned-non-sort-sub-title"
              >
                Planned Non-Sortables:
              </span>
            </div>
          </div>
          <div style={{ width: "28%" }}>
            <div>
              <span
                className="epic-body-text-1"
                test-id="planned-non-sort-value"
              >
                <b>{planDetails.plannedNonSortUnits}</b>
              </span>
            </div>
          </div>
        </div>

        <div className="proposed-section-div-position">
          <div className="proposed-table-div proposed-div-float-right">
            <span
              className="epic-title-4 table-label"
              test-id="shipping-point-params-title"
            >
              Shipping Point Parameters
            </span>
            <div className="table table-striped">
              <Table
                data={dcParameter}
                isCompact
                test-id="shipping-point-params-table"
                visibleRows={8}
                columns={[
                  {
                    Header: "Enable/Disable",
                    accessor: "state",
                  },
                  {
                    Header: "DC Name",
                    accessor: "shippingPointName",
                  },
                  {
                    Header: "DC Lead Time(Hrs)",
                    accessor: "leadTime",
                  },
                  {
                    Header: "Milk Run Time(Mins)",
                    accessor: "milkRunTime",
                  },
                ]}
              />
            </div>
          </div>
          <div className="proposed-table-div proposed-div-float-right">
            <span
              className="epic-title-4 table-label"
              test-id="carrier-point-params-title"
            >
              Carrier Parameters
            </span>
            <div className="table table-striped">
              <Table
                data={carrierParameter}
                isCompact
                test-id="carrier-point-params-table"
                visibleRows={8}
                columns={[
                  {
                    Header: "Enable/Disable",
                    accessor: "state",
                  },
                  {
                    Header: "Carrier",
                    accessor: "carrierName",
                  },
                  {
                    Header: "Cut-Off Time",
                    accessor: planDay,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="div-align-center">
          <Button
            medium="true"
            type="button"
            test-id="back-button"
            className="button"
            theme={{
              primary: "#000000",
              secondary: "#ffffff",
            }}
            onClick={() => executePlan()}
          >
            Back
          </Button>
        </div>
      </div>
      <PageSpinner show={showSpinner} />
    </div>
  );
};

export default ViewPreviousPlan;
