import React, { useState } from "react";

import MessageReprocess from "./MessageReprocess.js";
import BulkMessageReprocess from "./BulkMessageReprocess.js";
import { ToggleSwitch } from "@nike/epic-react-ui";

function FailedMessages() {
  const [selectedToggleValue, setSelectedToggleValue] = useState(true);
  let toggleList = [
    { value: true, label: "Error Details" },
    { value: false, label: "Bulk Retry" },
  ];

  return (
    <>
      <div className="row">
        <div className="col-10 "></div>
        <div className="col-2 ">
          <ToggleSwitch
            testid="test-toggleswitch"
            options={toggleList}
            value={selectedToggleValue}
            onChange={(selected) => setSelectedToggleValue(selected)}
          />
        </div>
      </div>
      <div className="row">
        {selectedToggleValue && <MessageReprocess></MessageReprocess>}
        {!selectedToggleValue && <BulkMessageReprocess></BulkMessageReprocess>}
      </div>
    </>
  );
}

export default FailedMessages;
