import React, { useState, useEffect } from "react";
import { ParameterService } from "../services/service.parameters";
import InlineEditableTable from "../components/InlineEditableTable.js";
import { toast } from "react-toastify";
import {
  Button,
  TextInput,
  IconButton,
  Modal,
  Table,
  PaginationTable,
} from "@nike/epic-react-ui";
const header = [
  {
    name: "Enable/Disable",
    prop: "state",
    type: "checkBoxType",
    isEditable: true,
  },
  {
    name: "Carrier Id",
    prop: "carrierAgentIdentifier",
    type: "textType",
    isEditable: true,
  },
  {
    name: "Carrier Name",
    prop: "carrierName",
    type: "textType",
    isEditable: true,
  },
  {
    name: "Sunday",
    prop: "cutOffCarrierSun",
    type: "timeType",
    isEditable: true,
  },
  {
    name: "Monday",
    prop: "cutOffCarrierMon",
    type: "timeType",
    isEditable: true,
  },
  {
    name: "Tuesday",
    prop: "cutOffCarrierTue",
    type: "timeType",
    isEditable: true,
  },
  {
    name: "Wednesday",
    prop: "cutOffCarrierWed",
    type: "timeType",
    isEditable: true,
  },
  {
    name: "Thursday",
    prop: "cutOffCarrierThu",
    type: "timeType",
    isEditable: true,
  },
  {
    name: "Friday",
    prop: "cutOffCarrierFri",
    type: "timeType",
    isEditable: true,
  },
  {
    name: "Saturday",
    prop: "cutOffCarrierSat",
    type: "timeType",
    isEditable: true,
  },
];
toast.configure();
function CutOffCarrier() {
  const [editIdx, setEditIdx] = useState(-1);
  const [tableData, setTableData] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [modalIdx, setModalIdx] = useState(-1);
  function selectDeleteRow(confirmMessage, index) {
    setConfirmMessage(confirmMessage);
    setModalIdx(index);
    setShowModal(true);
  }
  useEffect(async () => {
    await ParameterService.getCarrierParameters()
      .then((response) => {
        setTableData(prepareResponse(response).data.objects);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }, []);

  function prepareResponse(response) {
    let result = {};
    result.data = {};
    let objects = response.data.objects;
    objects.map(function (e) {
      let times = e.cutOffTimes;
      times.map(function (t) {
        if (t.day.toUpperCase() === "SUNDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierSun = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "MONDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierMon = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "TUESDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierTue = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "WEDNESDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierWed = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "THURSDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierThu = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "FRIDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierFri = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "SATURDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierSat = t.cutoffTime.substring(0, 5);
      });
    });
    result.data.objects = objects;
    return result;
  }

  async function getCarriers() {
    await ParameterService.getCarrierParameters()
      .then((response) => {
        setTableData(prepareResponse(response).data.objects);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }
  function startEditing(index) {
    if (disableButton) {
      const newTableData = [];
      tableData.map(function (row, j) {
        if (j > 0) newTableData[j - 1] = row;
      });
      setTableData(newTableData);
      setEditIdx(index - 1);
    } else {
      setEditIdx(index);
    }
    setDisableButton(false);
  }
  function addCarrier() {
    setDisableButton(true);

    const newCarrier = {
      state: 1,
      carrierAgentIdentifier: "",
      carrierName: "",
      cutOffCarrierMon: "",
      cutOffCarrierTue: "",
      cutOffCarrierWed: "",
      cutOffCarrierThu: "",
      cutOffCarrierFri: "",
      cutOffCarrierSat: "",
      cutOffCarrierSun: "",
      cutOffTimes: [
        {
          day: "FRIDAY",
          cutoffTime: "",
        },
        {
          day: "MONDAY",
          cutoffTime: "",
        },
        {
          day: "SATURDAY",
        },
        {
          day: "SUNDAY",
          cutoffTime: "",
        },
        {
          day: "THURSDAY",
          cutoffTime: "",
        },
        {
          day: "TUESDAY",
          cutoffTime: "",
        },
        {
          day: "WEDNESDAY",
          cutoffTime: "",
        },
      ],
    };

    const newTableData = [];
    newTableData[0] = newCarrier;
    setEditIdx(0);
    tableData.map((row, j) => (newTableData[j + 1] = row));
    setTableData(newTableData);
  }

  async function saveData(index, operation) {
    let payload = {};
    tableData.map(function (row, j) {
      if (j === index) payload = updateTimeData(row);
    });

    try {
      if (operation == "UPDATE") {
        if (index === 0 && disableButton) {
          await ParameterService.createCarrierParameters(payload)
            .then((response) => {
              toast.success("Carrier parameter created successfully");
              setEditIdx(-1);
              setDisableButton(false);
              getCarriers();
            })
            .catch((response) => {
              if (response != undefined && response.data != undefined)
                toast.error(response.data.message);
            });
        } else {
          await ParameterService.updateCarrierParametersById(
            payload.id,
            payload
          )
            .then((response) => {
              toast.success("Carrier parameter updated successfully");
              setDisableButton(false);
              setEditIdx(-1);
            })
            .catch((response) => {
              if (response != undefined && response.data != undefined)
                toast.error(response.data.message);
            });
        }
      }
      if (operation == "DELETE") {
        await ParameterService.deleteCarrierParametersById(payload.id)
          .then((response) => {
            toast.success("Carrier parameter deleted successfully");
            setEditIdx(-1);
            getCarriers();
          })
          .catch((response) => {
            if (response != undefined && response.data != undefined)
              toast.error(response.data.message);
          });
        setShowModal(false);
      }
    } catch (error) {
      toast.error(error.data.message);
    }
  }

  async function stopEditing() {
    await ParameterService.getCarrierParameters()
      .then((response) => {
        setTableData(prepareResponse(response).data.objects);
        setEditIdx(-1);
        setDisableButton(false);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }

  function handleChange(event, name, index) {
    if (name === "state") {
      const { checked } = event.target;
      setTableData(
        tableData.map((row, j) =>
          j === index ? { ...row, [name]: true === checked ? 1 : 0 } : row
        )
      );
    } else {
      const { value } = event.target;
      setTableData(
        tableData.map((row, j) =>
          j === index ? { ...row, [name]: value } : row
        )
      );
    }
  }

  function updateTimeData(row) {
    let times = row.cutOffTimes;
    times.map(function (t) {
      if (t.day.toUpperCase() === "SUNDAY") {
        if (row.cutOffCarrierSun === "" || row.cutOffCarrierSun === undefined) {
          t.cutoffTime = null;
        } else {
          t.cutoffTime = row.cutOffCarrierSun + ":00";
        }
      }
      if (t.day.toUpperCase() === "MONDAY") {
        if (row.cutOffCarrierMon === "" || row.cutOffCarrierMon === undefined) {
          t.cutoffTime = null;
        } else {
          t.cutoffTime = row.cutOffCarrierMon + ":00";
        }
      }
      if (t.day.toUpperCase() === "TUESDAY") {
        if (row.cutOffCarrierTue === "" || row.cutOffCarrierTue === undefined) {
          t.cutoffTime = null;
        } else {
          t.cutoffTime = row.cutOffCarrierTue + ":00";
        }
      }
      if (t.day.toUpperCase() === "WEDNESDAY") {
        if (row.cutOffCarrierWed === "" || row.cutOffCarrierWed === undefined) {
          t.cutoffTime = null;
        } else {
          t.cutoffTime = row.cutOffCarrierWed + ":00";
        }
      }
      if (t.day.toUpperCase() === "THURSDAY") {
        if (row.cutOffCarrierThu === "" || row.cutOffCarrierThu === undefined) {
          t.cutoffTime = null;
        } else {
          t.cutoffTime = row.cutOffCarrierThu + ":00";
        }
      }

      if (t.day.toUpperCase() === "FRIDAY") {
        if (row.cutOffCarrierFri === "" || row.cutOffCarrierFri === undefined) {
          t.cutoffTime = null;
        } else {
          t.cutoffTime = row.cutOffCarrierFri + ":00";
        }
      }

      if (t.day.toUpperCase() === "SATURDAY") {
        if (row.cutOffCarrierSat === "" || row.cutOffCarrierSat === undefined) {
          t.cutoffTime = null;
        } else {
          t.cutoffTime = row.cutOffCarrierSat + ":00";
        }
      }
    });
    return row;
  }

  return (
    <>
      <div className="row">
        <div className="col-10 table-label">
          <span className="epic-title-4">Carrier Parameter</span>
        </div>
        <div className="col-2 button-padding-carrier">
          <IconButton
            type="NikeApp"
            test-id="add-carrier-button"
            iconSize="m"
            theme={{
              primary: !disableButton ? "#228B22" : "rgba(215,250,215,0.62)",
              secondary: !disableButton ? "#ffffff" : "rgba(165,165,165,0.62)",
            }}
            onClick={addCarrier}
            disabled={disableButton}
          >
            Add Carrier
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <InlineEditableTable
            data={tableData}
            header={header}
            test-id="carrier-parameter-table"
            editIdx={editIdx}
            withDelete="YES"
            selectDeleteRow={selectDeleteRow}
            startEditing={startEditing}
            stopEditing={stopEditing}
            saveData={saveData}
            handleChange={handleChange}
          />{" "}
          <Modal
            onClose={() => setShowModal(false)}
            closeButton
            swoosh
            show={showModal}
            test-id="confirm-model"
          >
            <Modal.Title>
              <h2>Confirm Delete </h2>
            </Modal.Title>
            <Modal.Content>
              <p className="div-align-center">
                Press <code>&nbsp;Escape&nbsp;</code> to Exit
              </p>
              <h2>Do you want to delete {confirmMessage}?</h2>
            </Modal.Content>
            <Modal.Actions>
              <Button
                small
                className="button"
                onClick={() => setShowModal(false)}
                test-id="modal-no-button"
                theme={{
                  primary: "#000000",
                  secondary: "#ffffff",
                }}
              >
                No
              </Button>
              <Button
                small
                className="button"
                test-id="modal-yes-button"
                onClick={() => saveData(modalIdx, "DELETE")}
                theme={{
                  primary: "#228B22",
                  secondary: "#ffffff",
                }}
              >
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default CutOffCarrier;
