import merge from "deepmerge";

const base = {
    appFullName: "XDC Consol Plan",
    appName: "XDCCONSOL",
    description: "XDC Consol Plan",
    isProd: false,
    oAuth: {
      clientId: "nike.nodemgmt.xdcconsol",
      redirectUri: window.location.origin,
      scopes: [
        "fulfillment:plan::read: fulfillment:plan::update: fulfillment:plan::create: fulfillment:plan::delete: profile openid email",
      ],
    },
  },
  dev = merge(base, {
    parameterApiUrl:
      //"http://localhost:8080",
      "https://xdc-consol-parameter-svc-dev.nodemanagement-test.nikecloud.com",
    planningApiUrl:
      //"http://localhost:8080",
      "https://xdc-consol-planning-svc-dev.nodemanagement-test.nikecloud.com",
    weightsMeasuresApiUrl:
      //"http://localhost:8080",
      "https://xdc-consol-weights-measures-svc-dev.nodemanagement-test.nikecloud.com",
    messageReprocessApiUrl:
      //"http://localhost:8080",
      "https://xdc-consol-message-reprocess-svc-dev.nodemanagement-test.nikecloud.com",
    ingestionDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_dn_ingestion_nonprod?form.time_range.earliest=-60m%40m&form.time_range.latest=now&form.current_environment=dev&form.dn_number=%25&form.so_number=%25",
    preSignedDashboardUrl:
       "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_presigned_url_nonprod?form.time_range.earliest=-60m&form.time_range.latest=now&form.current_environment=dev",
    planningDashboardUrl:
       "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_planning_nonprod?form.time_range.earliest=-60m&form.time_range.latest=now&form.current_environment=dev",
    weightAndMeasureDashboardUrl:
       "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_weightsmeasures_nonprod?form.time_range.earliest=-60m%40m&form.time_range.latest=now&form.current_environment=dev",
  }),
  prod = merge(base, {
    parameterApiUrl:
      "https://xdc-consol-parameter-svc-prod.nodemanagement-prod.nikecloud.com",
    planningApiUrl:
      "https://xdc-consol-planning-svc-prod.nodemanagement-prod.nikecloud.com",
    weightsMeasuresApiUrl:
      "https://xdc-consol-weights-measures-svc-prod.nodemanagement-prod.nikecloud.com",
    messageReprocessApiUrl:
      "https://xdc-consol-message-reprocess-svc-prod.nodemanagement-prod.nikecloud.com",
    ingestionDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_dn_ingestion_prod?form.time_range.earliest=-60m%40m&form.time_range.latest=now",
    preSignedDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_presigned_url_prod?form.time_range.earliest=-60m&form.time_range.latest=now",
    planningDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consolplanning_prod?form.time_range.earliest=-60m%40m&form.time_range.latest=now",
    weightAndMeasureDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_weightsmeasures_prod?form.time_range.earliest=-60m%40m&form.time_range.latest=now",

    isProd: true,
  }),
  test = merge(base, {
    parameterApiUrl:
      "https://xdc-consol-parameter-svc-test.nodemanagement-test.nikecloud.com",
    planningApiUrl:
      "https://xdc-consol-planning-svc-test.nodemanagement-test.nikecloud.com",
    weightsMeasuresApiUrl:
      "https://xdc-consol-weights-measures-svc-test.nodemanagement-test.nikecloud.com",
    messageReprocessApiUrl:
      "https://xdc-consol-message-reprocess-svc-test.nodemanagement-test.nikecloud.com",
    ingestionDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_dn_ingestion_nonprod?form.time_range.earliest=-60m%40m&form.time_range.latest=now&form.current_environment=test&form.dn_number=%25&form.so_number=%25",
    preSignedDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_presigned_url_nonprod?form.time_range.earliest=-60m&form.time_range.latest=now&form.current_environment=test",
    planningDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_planning_nonprod?form.time_range.earliest=-60m&form.time_range.latest=now&form.current_environment=test",
    weightAndMeasureDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_weightsmeasures_nonprod?form.time_range.earliest=-60m%40m&form.time_range.latest=now&form.current_environment=test",


  }),
  stg = merge(base, {
    parameterApiUrl:
      "https://xdc-consol-parameter-svc-stg.nodemanagement-test.nikecloud.com",
    planningApiUrl:
      "https://xdc-consol-planning-svc-stg.nodemanagement-test.nikecloud.com",
    weightsMeasuresApiUrl:
      "https://xdc-consol-weights-measures-svc-stg.nodemanagement-test.nikecloud.com",
    messageReprocessApiUrl:
      "https://xdc-consol-message-reprocess-svc-stg.nodemanagement-test.nikecloud.com",
    ingestionDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_dn_ingestion_nonprod?form.time_range.earliest=-60m%40m&form.time_range.latest=now&form.current_environment=stg&form.dn_number=%25&form.so_number=%25",
    preSignedDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_presigned_url_nonprod?form.time_range.earliest=-60m&form.time_range.latest=now&form.current_environment=stg",
    planningDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_planning_nonprod?form.time_range.earliest=-60m&form.time_range.latest=now&form.current_environment=stg",
    weightAndMeasureDashboardUrl:
      "https://nike.splunkcloud.com/en-GB/app/commerce-dev-app/xdc_consol_weightsmeasures_nonprod?form.time_range.earliest=-60m%40m&form.time_range.latest=now&form.current_environment=stg",


  });

let env = dev;
if (window.location.origin.includes("xdcc-plan.nike.com") || window.location.origin.includes("xdc-consol-ui-prod.nodemanagement-prod.nikecloud.com")) {
  env = prod;
} else if (window.location.origin.includes("xdcc-plan-dev.nike.com")) {
  env = dev;
} else if (window.location.origin.includes("xdcc-plan-test.nike.com")) {
  env = test;
}
else if (window.location.origin.includes("xdcc-plan-stg.nike.com")) {
  env = stg;
}
const config = env;
export default config;
