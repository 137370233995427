import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "../styles/newplan.css";
import "../styles/appstyle.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import {
  Button,
  TextInput,
  IconButton,
  Modal,
  Table,
  PageSpinner,
  Label
} from "@nike/epic-react-ui";
import { PlanningService } from "../services/service.planning";
import { ParameterService } from "../services/service.parameters";
import XDC_ERROR_MESSAGES from "../error/error";
import { toast } from "react-toastify";

function NewPlan() {
  const history = useHistory();
  const [consolUnits, setConsolUnits] = useState("");
  const [planId, setPlanId] = useState("");
  const [currentDate, setCurrentDate] = useState(moment());
  const [label, setLabel] = useState("Units as of " + moment().format("YYYY-MM-DD HH:mm:ss"))
  const [planningCutOff, setPlanningCutOff] = useState(0);
  const [nonSortableCount, setNonSortableCount] = useState(0);
  const [plannableUnits, setPlannableUnits] = useState(0);
  const [nonSortableThreshold, setNonSortableThreshold] = useState(0);
  const [isValidPlanId, setIsValidPlanId] = useState(true);
  const [isValidPlanningCutOff, setIsValidPlanningCutOff] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [disablePlannableButton, setDisablePlannableButton] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isValidConsolUnits, setIsValidConsolUnits] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [disableMessage, setDisableMessage] = useState(XDC_ERROR_MESSAGES.INVALID_INPUT);

  //hook to load all the initial data required in the Total Non-Sortables available
  useEffect(async () => {
    let payload = {
      planTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await PlanningService.postNonSortUnits(payload);
    if (response.status === 200) {
      setNonSortableCount(response.data.totalNonSortableUnits);
      response.data.nonSortableByPGIDate.map(
        (pgiDate) =>
          (pgiDate.plannedGoodsIssueTimestamp =
            pgiDate.plannedGoodsIssueTimestamp.substring(0, 10))
      );
      setModalData(
        response.data.nonSortableByPGIDate.filter((pgiDateItem) => {
          return pgiDateItem.nonSortableUnits > 0;
        })
      );
    }
  }, []);

  //hook to load all the initial data required in the plannable units count
  useEffect(async () => {
    let payload = {
      planTime: moment.utc(currentDate).format("YYYY-MM-DD HH:mm:ss")
    };
    let response = await PlanningService.postPlannableUnits(payload);
    if (response.status === 200) {
      setPlannableUnits(response.data.plannableUnits);
      setDisablePlannableButton(false);
    }
  }, []);

  //hook to load the Non-Sortables threshold value configured from parameter settings
  useEffect(async () => {
    let response = await ParameterService.getNonSortParameters();
    setNonSortableThreshold(response.data.objects[0].nonSortableThreshold);
  }, []);

  //hook to validate the PlanId, PlanCutOff Time and Consolidation units.
  useEffect(async () => {
    if (isValidPlanId && isValidPlanningCutOff && isValidConsolUnits) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [isValidPlanningCutOff, isValidPlanId, isValidConsolUnits]);

  //hook to load the initial PlanId and PlanCutOff time calculated from DC lead Time.
  useEffect(async () => {
    let payload = {
      planTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await PlanningService.postPlanCutoffTime(payload);
    setPlanId(response.data.planSequenceId);
    if (response.data.planCutOffTime !== undefined)
      setPlanningCutOff(response.data.planCutOffTime.substring(0, 16));
  }, []);

  //function to validate the planning cutoff time in YYYY-MM-DD hh:mm.
  const validateAndSetPlaningCutOffTime = (planningCutOff) => {
    setPlanningCutOff(planningCutOff);
    const validPlanningCutOff = new RegExp(
      "^((2[0-9]{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) ([01][0-9]|2[0-3]):([0-5][0-9]))$"
    );
    setIsValidPlanningCutOff(validPlanningCutOff.test(planningCutOff));
  };

  //function to validate the plan id on format YYYYMMDD-WaveId.
  const validateAndSetPlanId = (planId) => {
    setPlanId(planId);
    const validPlanId = new RegExp(
      "^((2[0-9]{3})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])-([0-9][0-9]))$"
    );
    setIsValidPlanId(validPlanId.test(planId));
  };

  //function to validate the consolidation units entered.
  const validateAndSetConsolUnits = (consolUnits) => {
    setConsolUnits(consolUnits);
    setIsValidConsolUnits(consolUnits > 0);
  };

  //function to download the plannable units details.
  const getPlannableUnits = async () => {
    setDisablePlannableButton(true);
    setShowSpinner(true);
    let payload = {
      planTime: moment.utc(currentDate).format("YYYY-MM-DD HH:mm:ss")
    };
    await PlanningService.postPlannableUnitDetails(payload)
        .then((response) => {
          let element = document.createElement('a');
          element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data));
          element.setAttribute('download', "plannable_units_"+moment().format("YYYY-MM-DD_HH.mm.ss")+".csv");
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          setDisablePlannableButton(false);
          setShowSpinner(false);
        })
        .catch((response) => {
          toast.error(XDC_ERROR_MESSAGES.DOWNLOAD_PLANNABLE_DNS, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisablePlannableButton(false);
          setShowSpinner(false);
        });
  }

  const proposePlan = async () => {
    setDisableButton(true);
    setDisableMessage(XDC_ERROR_MESSAGES.DISABLE_NEW_PLAN);
    let payload = {
      planSequenceId: planId,
      requestedUnits: consolUnits,
      requestedNonSortUnits: nonSortableThreshold,
      planCutOffTime: planningCutOff + ":00",
    };
    await PlanningService.postPlanProposal(payload)
      .then((response) => {
        history.push({
          pathname: "/proposedplan",
          state: { planDetails: response.data },
        });
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        setDisableButton(false);
        setDisableMessage(XDC_ERROR_MESSAGES.INVALID_INPUT);
      });
  };

  return (
    <div
      style={{ flex: "50%", backgroundColor: "#f7f7f7" }}
      className=" div-align-center"
      id="test-input-id-main"
    >
      <div>
        <PageSpinner show={showSpinner} />
        <div>
          <span className="epic-title-4 table-label" test-id="main-title">
            New Plan
          </span>
        </div>
        <div className="label-div-margin div-align-center" style={{ justifyContent: 'left'}}>
          <span className="epic-title-5">
            <Label label={label} required/>
          </span>
        </div>
        <div className="label-div-margin div-align-center">
          <span className="epic-title-5 " test-id="sub-title-non-sortables" style={{ width: "90%"}}>
            Total Non-Sortables&nbsp;&nbsp;:&nbsp;{nonSortableCount}&nbsp;
          </span>
          <span>
            <IconButton
              test-id="non-sortables-preview-icon"
              iconClassName="preview-icon"
              buttonClassName="preview-icon"
              bare={true}
              extraSmall={true}
              type="Preview"
              iconSize="m"
              onClick={() => setShowModal(true)}
            />
          </span>
        </div>
        <div className="label-div-margin div-align-center">
          <span className="epic-title-5 " test-id="sub-title-plannable-units" style={{ width: "90%"}}>
            Units Available&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{plannableUnits}&nbsp;
          </span>
          <span>
            <IconButton
                test-id="plannable-units-download-icon"
                iconClassName="preview-icon"
                disabled={disablePlannableButton}
                buttonClassName="preview-icon"
                bare={true}
                extraSmall={true}
                type="Download"
                iconSize="m"
                onClick={getPlannableUnits}
            />
          </span>
        </div>

        <div className="div-label">
          <TextInput
            test-id="input-consol-units"
            type="number-np"
            required
            label="Consolidation Units"
            border={true}
            placeholder="# Units"
            onChange={(event) => validateAndSetConsolUnits(event.target.value)}
            hasErrors={!isValidConsolUnits}
            value={consolUnits}
          />
        </div>

        <div className="div-label">
          <TextInput
            required
            test-id="input-plan-id"
            type="text-np"
            label="Plan ID"
            border={true}
            onChange={(event) => validateAndSetPlanId(event.target.value)}
            placeholder="Plan ID in YYYYMMDD-ID"
            value={planId}
            hasErrors={!isValidPlanId}
            maxLength={11}
          />
        </div>
        <div className="div-label">
          <TextInput
            required
            test-id="input-plan-cutoff-time"
            type="text-np"
            label="Planning Cut-Off Time"
            border={true}
            onChange={(event) =>
              validateAndSetPlaningCutOffTime(event.target.value)
            }
            placeholder="Plan cut-off time in YYYY-MM-DD HH:MM"
            value={planningCutOff}
            hasErrors={!isValidPlanningCutOff}
            maxLength={19}
          />
        </div>
        <div className="div-label">
          <TextInput
            type="number-np"
            test-id="input-non-sortable-threshold"
            label="Non-Sortables Threshold"
            border={true}
            placeholder="Non Sortable Threshold"
            value={nonSortableThreshold}
            disabled={true}
          />
        </div>
        <div className="div-label button-padding">
          <IconButton
            type="NikeApp"
            test-id="submit-plan-button"
            iconSize="m"
            disabled={disableButton}
            onClick={proposePlan}
            disabledTip={{
              message: disableMessage,
              position: "right",
              size: "medium",
            }}
            theme={{
              primary: !disableButton ? "#228B22" : "rgba(215,250,215,0.62)",
              secondary: !disableButton ? "#ffffff" : "rgba(165,165,165,0.62)",
            }}
          >
            Propose Plan
          </IconButton>
        </div>
      </div>
      <>
        <Modal
          test-id="modal-id"
          onClose={() => setShowModal(false)}
          closeButton
          show={showModal}
        >
          <Modal.Title>
            <span
              className="epic-title-4 table-label modal-align-left"
              test-id="modal-title"
            >
              Non-Sortable Units by PGI Date
            </span>
          </Modal.Title>
          <Modal.Content>
            <div className="table table-striped table-condensed">
              <Table
                data={modalData}
                test-id="modal-table"
                isCompact
                columns={[
                  {
                    Header: "PGI Date",
                    accessor: "plannedGoodsIssueTimestamp",
                  },
                  {
                    Header: "#Non Sortable Units",
                    accessor: "nonSortableUnits",
                  },
                ]}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              medium="true"
              test-id="modal-close-button"
              className="button"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    </div>
  );
}
export default NewPlan;
