import React, { useState, useEffect, useMemo } from "react";
import Datetime from "react-datetime";
import "./containers.css";
import "react-datetime/css/react-datetime.css";
import { ReprocessService } from "../services/service.reprocess";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  Label,
  TableContainer,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "@nike/epic-react-ui";

function BulkMessageReprocess() {
  let currentDate = new Date();
  let oldDate = new Date();
  oldDate.setDate(oldDate.getDate() - 7);

  const [disableButton, setDisableButton] = useState(false);

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [selectedRowId, setSelectedRowId] = useState("");
  const [selectProcessName, setSelectProcessName] = useState("");
  const [selectErrorType, setSelectErrorType] = useState("");
  const [selectStartTimeStamp, setSelectStartTimeStamp] = useState("");
  const [selectEndTimeStamp, setSelectEndTimeStamp] = useState("");

  const [startTimeStamp, setStartTimeStamp] = useState(oldDate);
  const [endTimeStamp, setEndTimeStamp] = useState(currentDate);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getUTCMonth() + 1),
      day = "" + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hour = "" + d.getUTCHours(),
      minutes = "" + d.getUTCMinutes(),
      seconds = "" + d.getUTCSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (hour.length < 2) hour = "0" + hour;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;
    var date = [year, month, day].join("-");
    var time = [hour, minutes, seconds].join(":");
    return [date, time].join(" ");
  }

  function searchData() {
    if (startTimeStamp == "" || startTimeStamp == undefined) {
      toast.error("Start date is required");
      return;
    }
    if (endTimeStamp == "" || endTimeStamp == undefined) {
      toast.error("End date is required");
      return;
    }
    setDisableButton(true);
    setShowTable(false);
    setSelectStartTimeStamp(formatDate(startTimeStamp));
    setSelectEndTimeStamp(formatDate(endTimeStamp));
    let payload = {
      startTimeStamp: formatDate(startTimeStamp),
      endTimeStamp: formatDate(endTimeStamp),
    };
    ReprocessService.getBulkFailedEvents(payload)
      .then((response) => {
        if (response.data == "") toast.error("No data found");
        setData(prepareResponse(response).data.objects);
        setShowTable(true);
      })
      .catch((response) => {
        setData([]);
        if (
          response != undefined &&
          response.data != undefined &&
          response.data.message != undefined
        ) {
          let error = response.data.message;
          if (response.data.errors != undefined)
            response.data.errors.map(function (e) {
              error = e.message;
            });
          toast.error(error);
        }
      });

    setDisableButton(false);
  }

  useEffect(async () => {
    searchData();
  }, []);

  function updateStartTimestamp(value) {
    setStartTimeStamp(value);
    setData([]);
    setShowTable(false);
  }

  function updateEndTimestamp(value) {
    setEndTimeStamp(value);
    setData([]);
    setShowTable(false);
  }

  function selectRetryRow(rowId, sProcessName, sErrorType) {
    setSelectedRowId(rowId);
    setSelectProcessName(sProcessName);
    setSelectErrorType(sErrorType);
    setShowModal(true);
  }

  function prepareResponse(response) {
    let result = {};
    result.data = {};
    let objects = response.data.objects;
    objects.map(function (e) {
      e.id = e.processName + e.errorType;
    });
    result.data.objects = objects;
    return result;
  }

  async function saveData(rowId, sProcessName, sErrorType) {
    let payload = {
      processName: sProcessName,
      errorType: sErrorType,
      startTimeStamp: selectStartTimeStamp,
      endTimeStamp: selectEndTimeStamp,
    };
    try {
      await ReprocessService.bulkRetryEvents(payload)
        .then((response) => {
          toast.success("Bulk messsage re-processing retried successfully");
          searchData();
          setSelectedRowId("");
          setShowModal(false);
        })
        .catch((response) => {
          if (response != undefined && response.data != undefined)
            toast.error(response.data.message);
        });
    } catch (error) {
      toast.error(error.data.message);
    }
    setShowModal(false);
  }
  const columns = useMemo(
    () => [
      {
        Header: "Process Name",
        accessor: "processName",
        columnCount: 2,
      },
      {
        Header: "Error Type",
        accessor: "errorType",
        columnCount: 1,
      },
      {
        Header: "Error Reason",
        accessor: "errorReason",
        columnCount: 7,
      },
      {
        Header: "Count",
        accessor: "failureCount",
        columnCount: 1,
      },
    ],
    []
  );

  const props = {
    columns,
    data,
    isCompact: true,
    visibleRows: 11,
    className: "bulk-table-column-width",
  };

  return (
    <>
      <div className="row">
        <div className="col-2">
          <Label label="Start Date:" required>
            <Datetime
              value={startTimeStamp}
              onChange={updateStartTimestamp}
              dateFormat="DD/MM/yyyy"
              timeFormat="HH:mm:ss"
              closeOnSelect="true"
              testid="test-startdate"
            />
          </Label>
        </div>
        <div className="col-2">
          <Label label="End Date:" required>
            <Datetime
              value={endTimeStamp}
              onChange={updateEndTimestamp}
              dateFormat="DD/MM/yyyy"
              timeFormat="HH:mm:ss"
              closeOnSelect="true"
              testid="test-enddate"
            />
          </Label>
        </div>
        <div className="col-3 padding-bulk-search">
          <Button
            type="NikeApp"
            iconsize="m"
            testid="add-carrier-button"
            small
            onClick={searchData}
            disabled={disableButton}
            theme={{
              primary: !disableButton ? "#228B22" : "rgba(215,250,215,0.62)",
              secondary: !disableButton ? "#ffffff" : "rgba(165,165,165,0.62)",
            }}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="table-responsive min-height-bulk-search">
        {showTable && (
          <TableContainer
            className="table table-striped table-hover"
            testid="test-bulk-retry-table"
          >
            <Thead testid="test-bulk-retry-table-header">
              <Tr testid="test-bulk-retry-table-header-row">
                {props.columns.map((headerItem, headerIndex) => (
                  <Th
                    key={headerIndex}
                    className={`thead col-${headerItem.columnCount}`}
                    testid={`test-bulk-retry-table-header-column-${headerIndex}`}
                  >
                    {headerItem.Header}
                  </Th>
                ))}
                <Th className="col-1 td-compact-table-cell-icon thead" />
              </Tr>
            </Thead>
            <Tbody testid="test-bulk-retry-table-body">
              {props.data.map((dataItem, index) => (
                <Tr key={`tr-${index}`} testid={`tr-test-${index}`}>
                  {columns.map((head, headIndex) => (
                    <Td
                      key={`trc-${head.accessor}-${headIndex}`}
                      className="td-compact-table-cell-padding"
                      testid={`test-inline-table-body-column-${index}-${headIndex}`}
                    >
                      {dataItem[head.accessor]}
                    </Td>
                  ))}
                  <Td
                    key={`trse-${index}`}
                    className="col-1 td-compact-table-cell-icon"
                    testid={`test-inline-table-body-button-${index}`}
                  >
                    <Button
                      type="NikeApp"
                      iconsize="m"
                      small
                      disabled={!dataItem["retryable"]}
                      onClick={() =>
                        selectRetryRow(
                          dataItem["id"],
                          dataItem["processName"],
                          dataItem["errorType"]
                        )
                      }
                    >
                      Retry
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </TableContainer>
        )}
      </div>
      <>
        <Modal
          onClose={() => setShowModal(false)}
          closeButton
          swoosh
          show={showModal}
          testid="test-modal"
        >
          <Modal.Title testid="test-modal-title">
            <h2>Confirm Retry</h2>
          </Modal.Title>
          <Modal.Content testid="test-modal-content">
            <p className="div-align-center">
              Press <code>&nbsp;Escape&nbsp;</code> to Exit
            </p>
            <h2>Do you want to bulk retry for </h2>
            <h2>
              {selectProcessName} - {selectErrorType}?
            </h2>
            <p className="div-align-center"></p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              small
              className="button"
              onClick={() => setShowModal(false)}
              testid="modal-no-button"
              theme={{
                primary: "#000000",
                secondary: "#ffffff",
              }}
            >
              No
            </Button>
            <Button
              small
              className="button"
              testid="modal-yes-button"
              onClick={() =>
                saveData(
                  selectedRowId,
                  selectProcessName,
                  selectErrorType,
                  selectStartTimeStamp,
                  selectEndTimeStamp
                )
              }
              theme={{
                primary: "#228B22",
                secondary: "#ffffff",
              }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    </>
  );
}

export default BulkMessageReprocess;
