const XDC_ERROR_MESSAGES = {
  /**
   * New Plan Screen Error Messages
   */
  VALIDATE_PLANID: "Plan ID  Should be in YYYYMMDD-(0-99) format",
  VALIDATE_PLANCUTOFF: "Plan cut-off time should be in YYYY-MM-DD hh:mm",
  INVALID_INPUT: "Please correct the input values",
  DISABLE_NEW_PLAN: "Proposing plan",
  DOWNLOAD_PLANNABLE_DNS: "Unable to download plannable Unit Details. Please contact support team.",
  DOWNLOAD_PLANNED_DNS: "Unable to download plan details. Please contact support team.",
  VALIDATE_CONSOL_UNITS: "Please enter a valid number of consolidation units",
};

export default XDC_ERROR_MESSAGES;
