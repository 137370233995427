import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";

function getDcParameters(queryParams) {
  let url = API_INTERFACE_URI.DCPARAMS_PATH;
  if (queryParams != undefined) url = url + "?" + queryParams;
  return baseService.get(url, globalHeaders);
}
function getDcParametersById(id) {
  return baseService.getDataByIdParam(
    API_INTERFACE_URI.DCPARAMS_PATH + "/" + id,
    globalHeaders
  );
}
function updateDcParametersById(id, payload) {
  return baseService.put(
    API_INTERFACE_URI.DCPARAMS_PATH + "/" + id,
    globalHeaders,
    payload
  );
}

function getCarrierParameters(queryParams) {
  let url = API_INTERFACE_URI.CARRIERPARAMS_PATH;
  if (queryParams != undefined) url = url + "?" + queryParams;
  return baseService.get(url, globalHeaders);
}
function getCarrierParametersById(id) {
  return baseService.getDataByIdParam(
    API_INTERFACE_URI.CARRIERPARAMS_PATH + "/" + id,
    globalHeaders
  );
}
function deleteCarrierParametersById(id) {
  return baseService.delete(
    API_INTERFACE_URI.CARRIERPARAMS_PATH + "/" + id,
    globalHeaders
  );
}
function updateCarrierParametersById(id, payload) {
  return baseService.put(
    API_INTERFACE_URI.CARRIERPARAMS_PATH + "/" + id,
    globalHeaders,
    payload
  );
}
function createCarrierParameters(payload) {
  return baseService.post(
    API_INTERFACE_URI.CARRIERPARAMS_PATH,
    globalHeaders,
    payload
  );
}

function getNonSortParameters(queryParams) {
  return baseService.get(API_INTERFACE_URI.NONSORTPARAMS_PATH, globalHeaders);
}
function getNonSortParameterById(id) {
  return baseService.getDataByIdParam(
    API_INTERFACE_URI.NONSORTPARAMS_PATH + "/" + id,
    globalHeaders
  );
}
function updateNonSortParametersById(id, payload) {
  return baseService.put(
    API_INTERFACE_URI.NONSORTPARAMS_PATH + "/" + id,
    globalHeaders,
    payload
  );
}

export const ParameterService = {
  getDcParameters,
  getDcParametersById,
  updateDcParametersById,
  getCarrierParameters,
  getCarrierParametersById,
  updateCarrierParametersById,
  getNonSortParameters,
  getNonSortParameterById,
  updateNonSortParametersById,
  deleteCarrierParametersById,
  createCarrierParameters,
};
