import React, { useState, useEffect } from "react";
import { WeightsMeasuresService } from "../services/service.weightmeasures";
import InlineEditableTable from "../components/InlineEditableTable.js";
import { toast } from "react-toastify";
const header = [
  {
    name: "Length(cms)",
    prop: "length",
    type: "numberType",
    isEditable: true,
  },
  {
    name: "Width(cms)",
    prop: "width",
    type: "numberType",
    isEditable: true,
  },
  {
    name: "Height(cms)",
    prop: "height",
    type: "numberType",
    isEditable: true,
  },
  {
    name: "Weight(kgs)",
    prop: "weight",
    type: "numberType",
    isEditable: true,
  },
];

toast.configure();
function PouchConfiguration() {
  const [editIdx, setEditIdx] = useState(-1);
  const [tableData, setTableData] = useState([]);

  useEffect(async () => {
    await WeightsMeasuresService.getPouchParameters()
      .then((response) => {
        setTableData(response.data.objects);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }, []);

  function startEditing(index) {
    setEditIdx(index);
  }

  async function saveData(index) {
    let payload = {};
    tableData.map(function (row, j) {
      if (j === index) payload = row;
    });

    try {
      await WeightsMeasuresService.updatePouchParametersById(
        payload.id,
        payload
      )
        .then((response) => {
          toast.success("Pouch configuration updated successfully");
          setEditIdx(-1);
        })
        .catch((response) => {
          if (response != undefined && response.data != undefined)
            toast.error(response.data.message);
        });
    } catch (error) {
      error.data.errors.map((err) => {
        toast.error(err.message);
      });
    }
  }

  async function stopEditing() {
    await WeightsMeasuresService.getPouchParameters()
      .then((response) => {
        setTableData(response.data.objects);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });

    setEditIdx(-1);
  }

  function handleChange(event, name, index) {
    if (name === "state") {
      const { checked } = event.target;
      setTableData(
        tableData.map((row, j) =>
          j === index ? { ...row, [name]: checked } : row
        )
      );
    } else {
      const { value } = event.target;
      setTableData(
        tableData.map((row, j) =>
          j === index ? { ...row, [name]: value } : row
        )
      );
    }
  }

  return (
    <InlineEditableTable
      data={tableData}
      header={header}
      test-id="pouch-configuration-table"
      editIdx={editIdx}
      withDelete="NO"
      selectDeleteRow={function noRefCheck(index) {}}
      startEditing={startEditing}
      stopEditing={stopEditing}
      saveData={saveData}
      handleChange={handleChange}
    />
  );
}
export default PouchConfiguration;
