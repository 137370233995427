import React, { useState, useEffect } from "react";
import { ParameterService } from "../services/service.parameters";
import InlineEditableTable from "../components/InlineEditableTable.js";
import { toast } from "react-toastify";

const header = [
  {
    name: "Enable/Disable",
    prop: "state",
    type: "checkBoxType",
    isEditable: true,
  },
  {
    name: "DC Name",
    prop: "shippingPointName",
    type: "textType",
    isEditable: true,
  },
  {
    name: "Consol DC",
    prop: "consolShippingPointText",
    type: "textType",
    isEditable: false,
  },
  {
    name: "SAP Consol",
    prop: "sapConsoleEligibleText",
    type: "textType",
    isEditable: false,
  },
  {
    name: "DC Lead Time(Hrs)",
    prop: "leadTime",
    type: "numberType",
    isEditable: true,
  },
  {
    name: "Milk Run Time(Mins)",
    prop: "milkRunTime",
    type: "numberType",
    isEditable: true,
  },
];
toast.configure();
function DCEnableDisable() {
  const [editIdx, setEditIdx] = useState(-1);
  const [tableData, setTableData] = useState([]);

  useEffect(async () => {
    await ParameterService.getDcParameters()
      .then((response) => {
        setTableData(prepareResponse(response).data.objects);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }, []);

  function prepareResponse(response) {
    let result = {};
    result.data = {};
    let objects = response.data.objects;
    objects.map(function (e) {
      e.consolShippingPointText = e.consolShippingPoint === 1 ? "Yes" : "No";
      e.sapConsoleEligibleText = e.sapConsoleEligible === 1 ? "Yes" : "No";
    });
    result.data.objects = objects;
    return result;
  }

  function startEditing(index) {
    setEditIdx(index);
  }
  async function saveData(index) {
    let payload = {};
    tableData.map(function (row, j) {
      if (j === index) payload = row;
    });
    try {
      await ParameterService.updateDcParametersById(payload.id, payload)
        .then((response) => {
          toast.success("Shipping parameter updated successfully");
          setEditIdx(-1);
        })
        .catch((response) => {
          if (response != undefined && response.data != undefined)
            toast.error(response.data.message);
        });
    } catch (error) {
      error.data.errors.map((err) => {
        toast.error(err.message);
      });
    }
  }

  async function stopEditing() {
    await ParameterService.getDcParameters()
      .then((response) => {
        setTableData(prepareResponse(response).data.objects);
        setEditIdx(-1);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }

  function handleChange(event, name, index) {
    if (name === "state") {
      const { checked } = event.target;
      setTableData(
        tableData.map((row, j) =>
          j === index ? { ...row, [name]: true === checked ? 1 : 0 } : row
        )
      );
    } else {
      const { value } = event.target;
      setTableData(
        tableData.map((row, j) =>
          j === index ? { ...row, [name]: value } : row
        )
      );
    }
  }

  return (
    <InlineEditableTable
      data={tableData}
      header={header}
      test-id="dc-parameter-table"
      editIdx={editIdx}
      withDelete="NO"
      selectDeleteRow={function noRefCheck(index) {}}
      startEditing={startEditing}
      stopEditing={stopEditing}
      saveData={saveData}
      handleChange={handleChange}
    />
  );
}

export default DCEnableDisable;
