import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import { ReprocessService } from "../services/service.reprocess";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  Select,
  PaginationControlledTable,
} from "@nike/epic-react-ui";

function MessageReprocess() {
  const [data, setData] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [pageIdx, setPageIdx] = useState(0);
  const [selectedValue, setSelectedValue] = useState("All");
  const [retried, setRetried] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [selectedRowId, setSelectedRowId] = useState("");
  const [fileName, setFileName] = useState("");
  const fetchIdRef = useRef(0);
  const optionIdRef = useRef("");

  useEffect(async () => {
    await ReprocessService.getProcessNames()
      .then((response) => {
        setSelectData([
          { label: "All", value: "All" },
          ...response.data.objects.map(asOptions),
        ]);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }, []);

  function asOptions(process) {
    var option = { label: process.processName, value: process.processName };
    return option;
  }

  function getProcessEvents(selectedOption) {
    setSelectedValue(selectedOption);
  }

  function selectRetryRow(rowId, rowFileName) {
    setFileName(rowFileName);
    setSelectedRowId(rowId);
    setShowModal(true);
  }

  async function saveData(rowId, fileName) {
    let payload = { id: [rowId] };
    try {
      await ReprocessService.retryEvents(payload)
        .then((response) => {
          toast.success(
            "Messsage re-processing retried successfully for " + fileName
          );
          setRetried(rowId);
          setSelectedRowId("");
          setShowModal(false);
        })
        .catch((response) => {
          if (response != undefined && response.data != undefined)
            toast.error(response.data.message);
        });
    } catch (error) {
      toast.error(error.data.message);
    }
  }
  async function getEvents({ pageSize, pageIndex, sortByOrder }) {
    let queryParams = `count=${pageSize}&anchor=${pageIndex}`;

    if (
      selectedValue != undefined &&
      selectedValue != "" &&
      selectedValue != "All"
    ) {
      queryParams = queryParams + `&filters=processName(${selectedValue})`;
    }
    if (sortByOrder != "") {
      queryParams = queryParams + `&sort=${sortByOrder}`;
    }
    await ReprocessService.getFailedEvents(queryParams)
      .then((response) => {
        setData(response.data.objects);
        setTotalRows(response.data.pages.totalResources);
        setPageCount(response.data.pages.totalPages);
        setPageIdx(pageIndex);
      })
      .catch((response) => {
        setData([]);
        setTotalRows(0);
        setPageCount(0);
        setPageIdx(0);
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }

  const onTableChange = useCallback(
    ({ pageSize, pageIndex, globalFilter, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);
      let sortByOrder = "";
      if (optionIdRef.current != selectedValue) {
        pageIndex = 0;
        sortByOrder = "errorTimestampdesc";
      }
      // Only update the data if this is the latest fetch

      if (fetchId === fetchIdRef.current && data != undefined) {
        if (Array.isArray(sortBy) && sortBy.length) {
          sortByOrder =
            sortBy[0].id + (sortBy[0].desc === true ? "desc" : "asc");
        }
        getEvents({
          pageSize: Number(pageSize),
          pageIndex: Number(pageIndex),
          sortByOrder: sortByOrder,
        });
      }
      optionIdRef.current = selectedValue;
      setLoading(false);
    },
    [selectedValue, retried]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Process Name",
        accessor: "processName",
        disableSortBy: false,
        disableGlobalFilter: false,
      },
      {
        Header: "Payload",
        accessor: "sourceObjectKey",
        Cell: ({ row }) =>
          row.original.sourceObjectKey.replace(/^.*[\\\/]/, ""),
        disableSortBy: false,
        disableGlobalFilter: false,
      },

      {
        Header: "Error Date Time",
        accessor: "errorTimestamp",
        disableSortBy: false,
        disableGlobalFilter: false,
      },
      {
        Header: "Error Type",
        accessor: "errorType",
        disableSortBy: false,
        disableGlobalFilter: false,
      },
      {
        Header: "Error Reason",
        accessor: "errorReason",
        disableSortBy: false,
        disableGlobalFilter: false,
      },
      {
        Header: "Retry Count",
        accessor: "retryCount",
        disableSortBy: false,
        disableGlobalFilter: false,
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ row }) => (
          <Button
            type="NikeApp"
            iconsize="m"
            small
            disabled={!row.original.retryable}
            onClick={() =>
              selectRetryRow(
                row.original.id,
                row.original.sourceObjectKey.replace(/^.*[\\\/]/, "")
              )
            }
          >
            Retry
          </Button>
        ),
        disableSortBy: false,
        disableGlobalFilter: false,
      },
    ],
    []
  );

  const props = {
    columns,
    data,
    pageSizeOptions: [5, 10, 15],
    isCompact: true,
    //withSearchBar: true,
    initialState: {
      sortBy: [{ id: "errorTimestamp", desc: true }],
    },
    loading,
    pageCount: pageCount,
    onTableChange,
    totalRowCount: totalRows,
    pageIndex: pageIdx,
  };

  return (
    <>
      <div className="row">
        <div className="col-3 ">
          <Select
            menuPortalTarget={document.querySelector("body")}
            menuPosition={"fixed"}
            label="Process Name"
            value={{
              value: selectedValue,
              label: selectedValue,
            }}
            onChange={(selected) => getProcessEvents(selected.value)}
            onCreateOption={function noRefCheck() {}}
            onInputChange={function noRefCheck() {}}
            onMenuClose={function noRefCheck() {}}
            onMenuOpen={function noRefCheck() {}}
            options={selectData}
            testid="test-select"
          />
        </div>
      </div>
      <div className="row td-table-cell-word-wrap padding-message-table">
        <PaginationControlledTable {...props} testid="test-pagination-table"/>
      </div>
      <>
        <Modal
          onClose={() => setShowModal(false)}
          closeButton
          swoosh
          show={showModal}
          testid="test-modal"
        >
          <Modal.Title testid="test-modal-title">
            <h2>Confirm Retry</h2>
          </Modal.Title>
          <Modal.Content testid="test-modal-content">
            <p className="div-align-center">
              Press <code>&nbsp;Escape&nbsp;</code> to Exit
            </p>
            <h2>Do you want to retry payload</h2>
            <h2>{fileName}?</h2>
            <p className="div-align-center">
              Record will be removed from the list.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              small
              className="button"
              onClick={() => setShowModal(false)}
              testid="modal-no-button"
              theme={{
                primary: "#000000",
                secondary: "#ffffff",
              }}
            >
              No
            </Button>
            <Button
              small
              className="button"
              testid="modal-yes-button"
              onClick={() => saveData(selectedRowId, fileName)}
              theme={{
                primary: "#228B22",
                secondary: "#ffffff",
              }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    </>
  );
}

export default MessageReprocess;
