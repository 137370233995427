import React, { useEffect, useState } from "react";
import "@nike/epic-react-ui/dist/styles/main.css";
import "../styles/proposeplan.css";
import "../styles/appstyle.css";
import { Button, Modal, Table } from "@nike/epic-react-ui";
import { useHistory } from "react-router-dom";
import { ParameterService } from "../services/service.parameters";
import { toast } from "react-toastify";
import { PlanningService } from "../services/service.planning";

const ProposedPlan = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [dcParameter, setDcParameter] = useState([]);
  const [carrierParameter, setCarrierParameter] = useState([]);
  const [carrierParameterList, setCarrierParameterList] = useState([]);
  const [dcParameterList, setDcParameterList] = useState([]);
  const [planDay, setPlanDay] = useState("cutOffCarrierThu");
  let planDetails = props.location.state.planDetails;

  //hook to get the current shipping point parameter settings
  useEffect(async () => {
    let response = await ParameterService.getDcParameters();
    let dcParamList = [];
    response.data.objects.map((dc) => {
      dcParamList.push({ shippingParameterId: dc.shippingParamId });
      if (dc.state === 1) dc.state = "Enable";
      else dc.state = "Disable";
    });
    setDcParameter(response.data.objects);
    setDcParameterList(dcParamList);
  }, []);

  //hook to find the day of the week to load carrier cut-off times based on plan date
  useEffect(() => {
    let dayOfWeek = planDetails.carrierDay;
    if (dayOfWeek === "SUNDAY") setPlanDay("cutOffCarrierSun");
    if (dayOfWeek === "MONDAY") setPlanDay("cutOffCarrierMon");
    if (dayOfWeek === "TUESDAY") setPlanDay("cutOffCarrierTue");
    if (dayOfWeek === "WEDNESDAY") setPlanDay("cutOffCarrierWed");
    if (dayOfWeek === "THURSDAY") setPlanDay("cutOffCarrierThu");
    if (dayOfWeek === "FRIDAY") setPlanDay("cutOffCarrierFri");
    if (dayOfWeek === "SATURDAY") setPlanDay("cutOffCarrierSat");
  }, []);

  //hook to get the current carrier parameter settings
  useEffect(async () => {
    let response = await ParameterService.getCarrierParameters();
    let carrierParamList = [];
    response.data.objects.map((carrier) => {
      carrierParamList.push({ carrierParameterId: carrier.carrierParamId });
      if (carrier.state === 1) carrier.state = "Enable";
      else carrier.state = "Disable";
    });
    setCarrierParameter(prepareResponse(response).data.objects);
    setCarrierParameterList(carrierParamList);
  }, []);

  function prepareResponse(response) {
    let result = {};
    result.data = {};
    let objects = response.data.objects;
    objects.map(function (e) {
      let times = e.cutOffTimes;
      times.map(function (t) {
        if (t.day.toUpperCase() === "SUNDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierSun = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "MONDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierMon = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "TUESDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierTue = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "WEDNESDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierWed = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "THURSDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierThu = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "FRIDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierFri = t.cutoffTime.substring(0, 5);
        if (t.day.toUpperCase() === "SATURDAY" && t.cutoffTime !== undefined)
          e.cutOffCarrierSat = t.cutoffTime.substring(0, 5);
      });
    });
    result.data.objects = objects;
    return result;
  }

  //function to confirm/cancel the proposed plan.
  const executePlan = async (message, isConfirm) => {
    let requestPayload = { ...planDetails };
    requestPayload.planShippingParametrs = dcParameterList;
    requestPayload.planCarrierParametrs = carrierParameterList;
    if (isConfirm) {
      requestPayload.planStatus = "CONFIRMED";
    } else {
      requestPayload.planStatus = "CANCELLED";
    }
    await PlanningService.updatePlanBsyId(planDetails.id, requestPayload)
      .then((response) => {
        toast.success(message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      });
    history.push("/");
  };

  return (
    <div className="div-align-center">
      <div className="proposed-plan-root-div bg-color">
        <div className="proposed-div-padding">
          <span
            className="epic-title-3 table-label"
            test-id="propose-plan-title"
          >
            Proposed Plan
          </span>
        </div>
        <div className="proposed-section-div-position">
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="plan-id-sub-title"
            >
              Plan ID:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="plan-id-value">
              <b>{planDetails.planSequenceId}</b>
            </span>
          </div>
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="plan-cutoff-time-sub-title"
            >
              Planning Cut-Off Time:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="plan-cutoff-time-value">
              <b>{planDetails.planCutOffTime.substring(0, 16)}</b>
            </span>
          </div>
        </div>

        <div className="proposed-section-div-position">
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="req-units-sub-title"
            >
              Requested Consolidation Units:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="req-units-value">
              <b>{planDetails.requestedUnits}</b>
            </span>
          </div>
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <span
              className="epic-body-text-1 sub-label"
              test-id="proposed-units-sub-title"
            >
              Proposed Consolidation Units:
            </span>
          </div>
          <div style={{ width: "28%" }}>
            <span className="epic-body-text-1" test-id="proposed-units-value">
              <b>{planDetails.plannedUnits}</b>
            </span>
          </div>
        </div>

        <div className="proposed-section-div-position">
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <div>
              <span
                className="epic-body-text-1 sub-label"
                test-id="non-sort-threshold-sub-title"
              >
                Non-Sortables Threshold:
              </span>
            </div>
          </div>
          <div style={{ width: "28%" }}>
            <div>
              <span className="epic-body-text-1" test-id="non-sort-value">
                <b>{planDetails.requestedNonSortUnits}</b>
              </span>
            </div>
          </div>
          <div
            className="proposed-section-div-padding-left"
            style={{ width: "28%" }}
          >
            <div>
              <span
                className="epic-body-text-1 sub-label"
                test-id="planned-non-sort-sub-title"
              >
                Planned Non-Sortables:
              </span>
            </div>
          </div>
          <div style={{ width: "28%" }}>
            <div>
              <span
                className="epic-body-text-1"
                test-id="planned-non-sort-value"
              >
                <b>{planDetails.plannedNonSortUnits}</b>
              </span>
            </div>
          </div>
        </div>

        <div className="proposed-section-div-position">
          <div className="proposed-table-div proposed-div-float-right">
            <span
              className="epic-title-4 table-label"
              test-id="shipping-point-params-title"
            >
              Shipping Point Parameters
            </span>
            <div className="table table-striped">
              <Table
                data={dcParameter}
                test-id="shipping-point-params-table"
                isCompact
                visibleRows={8}
                columns={[
                  {
                    Header: "Enable/Disable",
                    accessor: "state",
                  },
                  {
                    Header: "DC Name",
                    accessor: "shippingPointName",
                  },
                  {
                    Header: "DC Lead Time(Hrs)",
                    accessor: "leadTime",
                  },
                  {
                    Header: "Milk Run Time(Mins)",
                    accessor: "milkRunTime",
                  },
                ]}
              />
            </div>
          </div>
          <div className="proposed-table-div proposed-div-float-right">
            <span
              className="epic-title-4 table-label"
              test-id="carrier-point-params-title"
            >
              Carrier Parameters
            </span>
            <div className="table table-striped">
              <Table
                data={carrierParameter}
                test-id="carrier-point-params-table"
                isCompact
                visibleRows={8}
                columns={[
                  {
                    Header: "Enable/Disable",
                    accessor: "state",
                  },
                  {
                    Header: "Carrier",
                    accessor: "carrierName",
                  },
                  {
                    Header: "Cut-Off Time",
                    accessor: planDay,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="div-align-center">
          <Button
            medium="true"
            test-id="cancel-button"
            type="button"
            className="button"
            theme={{
              primary: "#000000",
              secondary: "#ffffff",
            }}
            onClick={() =>
              executePlan(
                "Plan " +
                  planDetails.planSequenceId +
                  " cancelled successfully",
                false
              )
            }
          >
            Cancel
          </Button>
          &nbsp;
          <Button
            medium="true"
            type="button"
            test-id="confirm-button"
            className="button"
            theme={{
              primary: "#228B22",
              secondary: "#ffffff",
            }}
            onClick={() => setShowModal(true)}
          >
            Confirm
          </Button>
          <>
            <Modal
              onClose={() => setShowModal(false)}
              closeButton
              swoosh
              show={showModal}
              test-id="confirm-model"
            >
              <Modal.Title>
                <h2>Confirm Plan</h2>
              </Modal.Title>
              <Modal.Content>
                <p className="div-align-center">
                  Press <code>&nbsp;Escape&nbsp;</code> to Exit
                </p>
                <h2>Do you want to proceed?</h2>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  small
                  className="button"
                  onClick={() => setShowModal(false)}
                  test-id="modal-no-button"
                  theme={{
                    primary: "#000000",
                    secondary: "#ffffff",
                  }}
                >
                  No
                </Button>
                <Button
                  small
                  className="button"
                  test-id="modal-yes-button"
                  onClick={() =>
                    executePlan(
                      "Plan " +
                        planDetails.planSequenceId +
                        " confirmed successfully",
                      true
                    )
                  }
                  theme={{
                    primary: "#228B22",
                    secondary: "#ffffff",
                  }}
                >
                  Yes
                </Button>
              </Modal.Actions>
            </Modal>
          </>
        </div>
      </div>
    </div>
  );
};

export default ProposedPlan;
