import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";

function getPlans(queryParams) {
  let url = API_INTERFACE_URI.PLANS_PATH;
  if (queryParams != undefined) url = url + "?" + queryParams;
  return baseService.get(url, globalHeaders);
}
function getPlansById(id) {
  return baseService.getDataByIdParam(
    API_INTERFACE_URI.PLANS_PATH + "/" + id,
    globalHeaders
  );
}
function updatePlanBsyId(id, payload) {
  return baseService.put(
    API_INTERFACE_URI.PLANS_PATH + "/" + id,
    globalHeaders,
    payload
  );
}

function getFulfillmentRequests() {
  return baseService.get(API_INTERFACE_URI.CARRIERPARAMS_PATH, globalHeaders);
}
function getFulfillmentRequestsById(id) {
  return baseService.getDataByIdParam(
    API_INTERFACE_URI.CARRIERPARAMS_PATH + "/" + id,
    globalHeaders
  );
}

function getNonSortParameters() {
  return baseService.get(API_INTERFACE_URI.NONSORTPARAMS_PATH, globalHeaders);
}
function getNonSortParameterById(id) {
  return baseService.getDataByIdParam(
    API_INTERFACE_URI.NONSORTPARAMS_PATH + "/" + id,
    globalHeaders
  );
}
function updateNonSortParam(id, payload) {
  return baseService.put(
    API_INTERFACE_URI.NONSORTPARAMS_PATH + "/" + id,
    globalHeaders,
    payload
  );
}

function postPlanCutoffTime(payload) {
  return baseService.post(
    API_INTERFACE_URI.POST_PLANCUTOFFTIME,
    globalHeaders,
    payload
  );
}
function postPlanProposal(payload) {
  return baseService.post(
    API_INTERFACE_URI.POST_PLANPROPOSAL,
    globalHeaders,
    payload
  );
}

function postNonSortUnits(payload) {
  return baseService.post(
    API_INTERFACE_URI.POST_NONSORT_UNITS,
    globalHeaders,
    payload
  );
}

function postPlannableUnits(payload) {
  return baseService.post(
      API_INTERFACE_URI.POST_PLANNABLE_UNITS_COUNT,
      globalHeaders,
      payload
  );
}

function postPlannableUnitDetails(payload) {
  return baseService.post(API_INTERFACE_URI.POST_PLANNABLE_UNIT_DETAILS, globalHeaders, payload);
}

function getPlanDetailsById(id) {
  return baseService.getDataByIdParam(
      API_INTERFACE_URI.PLANDETAILS_PATH + "/" + id,
      globalHeaders
  );
}

export const PlanningService = {
  getPlans,
  getPlansById,
  updatePlanBsyId,
  getFulfillmentRequests,
  getFulfillmentRequestsById,
  getNonSortParameters,
  getNonSortParameterById,
  updateNonSortParam,
  postPlanCutoffTime,
  postPlanProposal,
  postNonSortUnits,
  postPlannableUnits,
  postPlannableUnitDetails,
  getPlanDetailsById
};
