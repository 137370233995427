import axios from "axios";
import http from "../utilities/http";

const baseService = {
  get,
  put,
  post,
  delete: _delete,
  getDataByIdParam,
};

function get(url, headers) {
  const result = axios
    .get(url, {
      headers,
    })
    .then(handleResponse)
    .catch(error);
  return result;
}

function put(url, headers, body) {
  return axios
    .put(url, body, {
      headers: headers,
    })
    .then(handleResponse)
    .catch(error);
}

function post(url, headers, body) {
  return axios
    .post(url, body, {
      headers: headers,
    })
    .then(handleResponse)
    .catch(error);
}

function _delete(url, headers) {
  return axios
    .delete(url, {
      headers: headers,
    })
    .then(handleResponse)
    .catch(error);
}

function getDataByIdParam(url, headers) {
  return axios
    .get(url, {
      headers: headers,
    })
    .then(handleResponse)
    .catch(error);
}

function handleResponse(response) {
  if (!http.isValidHttpCode(response.status)) {
    return Promise.reject(response);
  }
  return response;
}

function error(error) {
  let errorResponse = "";
  if (axios.isCancel(error)) {
    errorResponse = error.message;
  } else {
    errorResponse = error.response
      ? error.response
      : { response: { status: 500 } };
  }
  return Promise.reject(errorResponse);
}

export default baseService;
