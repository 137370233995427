import React from "react";
import { Button, Icon } from "@nike/epic-react-ui";
import "./component.css";
function Header({ handleLogout, displayLogout }) {
  return (
    <div className="header">
      <div className="row">
        <div className="offset-4 col-4 div-align-center">
          <Icon size="l" type="NikeApp" className="table-label icon-size" />
          <span className="epic-display-3">X-DC Consolidation</span>
        </div>
        {displayLogout && (
          <div className="offset-3 col-1">
            <Button extraSmall onClick={handleLogout} className="mt-2">
              Log Out
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default Header;
