import React from "react";
function NotFoundPage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>Oops! We couldn't find the page you were looking for.</h2>
    </div>
  );
}

export default NotFoundPage;
