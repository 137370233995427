import React, { useState } from "react";
import "./component.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import { Checkbox, IconButton, TextInput, Td } from "@nike/epic-react-ui";

function InlineEditableRow({
  dataItem,
  index,
  header,
  handleChange,
  saveData,
  stopEditing,
}) {
  const [isInValidTimeField, setIsInValidTimeField] = useState("blank");
  const [isInValidNumber, setIsInValidNumber] = useState("blank");
  const [isInValidText, setIsInValidText] = useState("blank");
  const [disableSave, setDisableSave] = useState(true);

  function validateTime(event, headProp, index) {
    setIsInValidTimeField("blank");
    setDisableSave(false);
    const { value } = event.target;
    if (value === "") {
      handleChange(event, headProp, index);
      return;
    }
    const validTime = new RegExp("^(([01][0-9]|2[0-3]):([0-5][0-9]))$");
    if (!validTime.test(value)) {
      setIsInValidTimeField(headProp);
      setDisableSave(true);
    }
    handleChange(event, headProp, index);
  }

  function validateNumber(event, headProp, index) {
    setIsInValidNumber("blank");
    setDisableSave(false);
    const { value } = event.target;
    if (!value || value < 0) {
      setIsInValidNumber(headProp);
      setDisableSave(true);
    }
    handleChange(event, headProp, index);
  }

  function validateText(event, headProp, index) {
    setIsInValidText("blank");
    setDisableSave(false);
    const { value } = event.target;
    if (!value || value < 0) {
      setIsInValidText(headProp);
      setDisableSave(true);
    }
    handleChange(event, headProp, index);
  }

  function validate() {
    let isError = false;
    return isError;
  }
  async function onSubmit(e) {
    e.preventDefault();
    const err = validate();
    if (!err) {
      saveData(index, "UPDATE");
    }
  }

  function handleCheckboxChange(event, headProp, index) {
    handleChange(event, headProp, index);
    setDisableSave(false);
  }

  return [
    header.map((head, headIndex) => (
      <Td
        key={`trcif-${head.prop}-${headIndex}`}
        className="td-compact-table-cell-padding"
        //testid="test-inline-row"
      >
        {head.type === "checkBoxType" && (
          <Checkbox
            checked={dataItem[head.prop]}
            onChange={(event) => handleCheckboxChange(event, head.prop, index)}
            onValueChange={(event) =>
              handleCheckboxChange(event, head.prop, index)
            }
            testid={`test-inline-row-${headIndex}`}
          />
        )}
        {head.type === "timeType" && (
          <TextInput
            type="text"
            placeholder="HH:MM"
            name={head.prop}
            onChange={(event) => validateTime(event, head.prop, index)}
            value={dataItem[head.prop]}
            disabled={false}
            hasErrors={head.prop === isInValidTimeField}
            testid={`test-inline-row-${headIndex}`}
          />
        )}
        {head.type == "textType" && !head.isEditable && dataItem[head.prop]}
        {head.type == "textType" && head.isEditable && (
          <TextInput
            required
            type="text"
            name={head.prop}
            onChange={(event) => validateText(event, head.prop, index)}
            value={dataItem[head.prop]}
            disabled={false}
            hasErrors={!dataItem[head.prop] || head.prop === isInValidText}
            testid={`test-inline-row-${headIndex}`}
          />
        )}
        {head.type == "numberType" && (
          <TextInput
            required
            name={head.prop}
            onChange={(event) => validateNumber(event, head.prop, index)}
            value={dataItem[head.prop]}
            disabled={false}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            hasErrors={dataItem[head.prop] < 0 || head.prop === isInValidNumber}
            testid={`test-inline-row-${headIndex}`}
          />
        )}
      </Td>
    )),
    <Td
      key={`trchs-${index}`}
      className="col-1 td-compact-table-cell-icon"
      testid="test-inline-row-button"
    >
      <IconButton
        type="Check"
        bare
        iconSize="m"
        small
        onClick={onSubmit}
        disabled={disableSave}
        testid="test-button-check"
      />
      <IconButton
        type="Close"
        bare
        iconSize="m"
        testid="test-button-close"
        small
        onClick={() => stopEditing()}
      />
    </Td>,
  ];
}

export default InlineEditableRow;
