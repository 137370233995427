import API_INTERFACE_URI from "../constants/uri.constants";
import baseService from "./service";
import { globalHeaders } from "../utilities/http";

function getPouchParameters() {
  return baseService.get(API_INTERFACE_URI.POUCHPARAMS_PATH, globalHeaders);
}
function getPouchParametersById(id) {
  return baseService.getDataByIdParam(
    API_INTERFACE_URI.POUCHPARAMS_PATH + "/" + id,
    globalHeaders
  );
}
function updatePouchParametersById(id, payload) {
  return baseService.put(
    API_INTERFACE_URI.POUCHPARAMS_PATH + "/" + id,
    globalHeaders,
    payload
  );
}

function postNonSortFlag(payload) {
  return baseService.post(
    API_INTERFACE_URI.POST_NON_SORT_FLAG,
    globalHeaders,
    payload
  );
}

export const WeightsMeasuresService = {
  getPouchParameters,
  getPouchParametersById,
  updatePouchParametersById,
  postNonSortFlag,
};
