import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { PlanningService } from "../services/service.planning";
import { Link } from "react-router-dom";
import { PaginationControlledTable } from "@nike/epic-react-ui";
import { toast } from "react-toastify";

function PreviousPlan() {
  const columns = useMemo(
    () => [
      {
        Header: "Planning Id",
        accessor: "planSequenceId",
        Cell: ({ row }) => (
          <Link className="td-hyperlink"
            to={{
              pathname: `/plan/${row.original.planSequenceId}`,
              state: { planDetails: row.original },
            }}
          >
            {row.original.planSequenceId}
          </Link>
        ),
        disableSortBy: false,
        disableGlobalFilter: false,
      },
      {
        Header: "# Requested Units",
        accessor: "requestedUnits",
        disableSortBy: false,
        disableGlobalFilter: false,
      },
      {
        Header: "# Planned Units",
        accessor: "plannedUnits",
        disableSortBy: false,
        disableGlobalFilter: false,
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowCount, setRowCount] = useState(5);
  const fetchIdRef = useRef(0);

  async function getPreviousPlans({ pageSize, pageIndex, sortByOrder }) {
    let queryParams = `count=${pageSize}&anchor=${pageIndex}`;

    if (sortByOrder != "") {
      queryParams = queryParams + `&sort=${sortByOrder}`; //"planSequenceIdDesc"
    }

    await PlanningService.getPlans(queryParams)
      .then((response) => {
        setData(response.data.objects);
        setTotalRows(response.data.pages.totalResources);
        setPageCount(response.data.pages.totalPages);
      })
      .catch((response) => {
        if (response != undefined && response.data != undefined)
          toast.error(response.data.message);
      });
  }

  const onTableChange = useCallback(
    ({ pageSize, pageIndex, globalFilter, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);
      setRowCount(Number(pageSize));
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current && data != undefined) {
        let sortByOrder = "";
        if (Array.isArray(sortBy) && sortBy.length) {
          sortByOrder =
            sortBy[0].id + (sortBy[0].desc === true ? "desc" : "asc");
        }
        getPreviousPlans({
          pageSize: Number(pageSize),
          pageIndex: Number(pageIndex),
          sortByOrder: sortByOrder,
        });
      }
      setLoading(false);
    },
    []
  );

  const props = {
    columns,
    data,
    pageSizeOptions: [5, 10, 15],
    isCompact: true,
    //withSearchBar: true,
    initialState: {
      sortBy: [{ id: "planSequenceId", desc: true }],
    },
    pageSize: rowCount,
    loading,
    pageCount,
    onTableChange,
    totalRowCount: totalRows,
  };

  return <PaginationControlledTable test-id="previous-plans-table" {...props} />;
}

export default PreviousPlan;
