import React, { useState } from "react";
import PropTypes from "prop-types";
import "./component.css";
import "@nike/epic-react-ui/dist/styles/main.css";
import InlineEditableRow from "./InlineEditableRow.js";
import {
  Checkbox,
  IconButton,
  TableContainer,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  Modal,
} from "@nike/epic-react-ui";

const renderRow = (
  dataItem,
  index,
  header,
  startEditing,
  editIdx,
  withDelete,
  selectDeleteRow,
  handleChange,
  saveData,
  stopEditing
) => {
  const currentlyEditing = editIdx === index;
  return currentlyEditing ? (
    <Tr key={`tr-${index}`} testid={`tr-test-${index}`}>
      <InlineEditableRow
        dataItem={dataItem}
        index={index}
        header={header}
        startEditing={startEditing}
        editIdx={editIdx}
        saveData={saveData}
        stopEditing={stopEditing}
        handleChange={handleChange}
      ></InlineEditableRow>
    </Tr>
  ) : (
    <Tr key={`tr-${index}`} testid={`tr-test-${index}`}>
      {header.map((head, headIndex) => (
        <Td
          key={`trc-${head.prop}-${headIndex}`}
          className="td-compact-table-cell-padding"
          testid={`test-inline-table-body-column-${index}-${headIndex}`}
        >
          {head.type === "checkBoxType" && (
            <Checkbox checked={dataItem[head.prop]} disabled />
          )}
          {head.type !== "checkBoxType" && dataItem[head.prop]}
        </Td>
      ))}
      <Td
        key={`trse-${index}`}
        className="col-1 td-compact-table-cell-icon"
        testid={`test-inline-table-body-button-${index}`}
      >
        {!currentlyEditing && (
          <IconButton
            type="Edit"
            bare
            iconSize="m"
            small
            onClick={() => startEditing(index)}
            testid={`test-icon-button-${index}`}
          />
        )}
        {!currentlyEditing && "YES" == withDelete && (
          <IconButton
            type="delete"
            bare
            iconSize="l"
            iconFontSize="22px"
            small
            onClick={() => selectDeleteRow(dataItem.carrierName, index)}
            testid={`delete-icon-button-${index}`}
          />
        )}
      </Td>
    </Tr>
  );
};

function InlineEditableTable(props) {
  return (
    <div className="table-responsive">
      <TableContainer
        className="table table-striped table-hover"
        testid="test-inline-table"
      >
        <Thead testid="test-inline-table-header">
          <Tr testid="test-inline-table-header-row">
            {props.header.map((headerItem, headerIndex) => (
              <Th
                key={headerIndex}
                isSorted={true}
                isSortedDesc={true}
                className="thead"
                testid={`test-inline-table-header-column-${headerIndex}`}
              >
                {headerItem.name}
              </Th>
            ))}
            <Th className="col-1 td-compact-table-cell-icon thead" />
          </Tr>
        </Thead>
        <Tbody testid="test-inline-table-body">
          {props.data.map((dataItem, index) =>
            renderRow(
              dataItem,
              index,
              props.header,
              props.startEditing,
              props.editIdx,
              props.withDelete,
              props.selectDeleteRow,
              props.handleChange,
              props.saveData,
              props.stopEditing
            )
          )}
        </Tbody>
      </TableContainer>
    </div>
  );
}

InlineEditableTable.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  editIdx: PropTypes.number.isRequired,
  startEditing: PropTypes.func.isRequired,
};

InlineEditableTable.defaultProps = {
  data: [],
  header: [],
  editIdx: -1,
};
export default InlineEditableTable;
