import React from "react";
import { TabPanel } from "react-tabs";
import PreviousPlan from "./PreviousPlan.js";
import NewPlan from "./NewPlan.js";
import CutOffCarrier from "./CutOffCarrier.js";
import FailedMessages from "./FailedMessages.js";
import DCEnableDisable from "./DCEnableDisable.js";
import NonSortableThreshold from "./NonSortableThreshold.js";
import PouchConfiguration from "./PouchConfiguration.js";

import "@nike/epic-react-ui/dist/styles/main.css";
import { Tabs } from "@nike/epic-react-ui";
import Dashboards from "./Dashboards";

function ConsolPlan() {
  return (
    <Tabs
      className="tabs bg-color-tabs"
      selectedTabPanelClassName="activeTabPanel"
      tabs={["Consol Planning", "Parameter Setting", "Message Reprocessing", "Dashboards"]}
    >
      <TabPanel className="tabPanel bg-color padding-tabs">
        <div className="row">
          <div className="col-8">
            <span className="epic-title-4 table-label">Previous Plans</span>
            <PreviousPlan></PreviousPlan>
          </div>
          <div className="col-4">
            <NewPlan></NewPlan>
          </div>
        </div>
      </TabPanel>
      <TabPanel className="tabPanel bg-color padding-tabs">
        <div className="row">
          <div className="col-7">
            <span className="epic-title-4 table-label">
              Shipping Point Parameter
            </span>
            <DCEnableDisable></DCEnableDisable>
          </div>
          <div className="col-5">
            <span className="epic-title-4 table-label">
              Non Sortable Parameter
            </span>
            <NonSortableThreshold></NonSortableThreshold>
            <br />

            <span className="epic-title-4 table-label">
              Pouch Configuration
            </span>
            <PouchConfiguration></PouchConfiguration>
          </div>
        </div>
        <div className="col-12 table-label">
          <CutOffCarrier></CutOffCarrier>
        </div>
      </TabPanel>
      <TabPanel className="tabPanel bg-color padding-tabs">
        <div className="col-12 table-label">
          <FailedMessages></FailedMessages>
        </div>
      </TabPanel>
      <TabPanel className="tabPanel bg-color padding-tabs">
        <div className="col-12 table-label">
          <Dashboards></Dashboards>
        </div>
      </TabPanel>
    </Tabs>
  );
}

export default ConsolPlan;
