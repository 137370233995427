import config from "../config/config.js";
import React from "react";
import {
   TableContainer, Tbody, Tr, Td
} from "@nike/epic-react-ui";

const Dashboards = () => {

  return (
    <div className="row" testid="test-dashboard">
      
    <div  className="col-3"> 
    <span className="epic-title-4 table-label">
      <h4>&nbsp;Splunk </h4>
    </span>

    <TableContainer className="table table-striped table-hover">
    <Tbody>
       <Tr>
         <Td className="col-1 td-compact-table-cell-icon td-hyperlink"><a href = {config.ingestionDashboardUrl} target="_blank"><u>Ingestion Service</u></a></Td>
      </Tr>
      <Tr>
        <Td className="td-compact-table-cell-icon td-hyperlink"><a href = {config.preSignedDashboardUrl} target="_blank"><u>Pre Signed Url</u></a></Td>
      </Tr>
      <Tr>
        <Td className="td-compact-table-cell-icon td-hyperlink"><a href = {config.planningDashboardUrl} target="_blank"><u>Planning Service</u></a></Td>
      </Tr>
      <Tr>
        <Td className="td-compact-table-cell-icon td-hyperlink"><a href = {config.weightAndMeasureDashboardUrl} target="_blank"><u>Weight & Measure Service</u></a></Td>
      </Tr>
  </Tbody>
 </TableContainer>

</div>
</div>

)
}

export default Dashboards;