import config from "../config/config.js";

const API_INTERFACE_URI = {
  /**
   * Parameter service endpoints
   */
  DCPARAMS_PATH: `${config.parameterApiUrl}/nodemgtxdcc/dcparams/v1`,

  CARRIERPARAMS_PATH: `${config.parameterApiUrl}/nodemgtxdcc/carrierparams/v1`,

  NONSORTPARAMS_PATH: `${config.parameterApiUrl}/nodemgtxdcc/nonsortparams/v1`,

  /**
   * Planning service endpoints
   */
  PLANS_PATH: `${config.planningApiUrl}/nodemgtxdcc/plans/v1`,
  POST_PLANCUTOFFTIME: `${config.planningApiUrl}/nodemgtxdcc/plancutofftime/v1`,
  POST_PLANPROPOSAL: `${config.planningApiUrl}/nodemgtxdcc/planproposal/v1`,
  FULFILLMENTREQUESTS_PATH: `${config.planningApiUrl}/nodemgtxdcc/fulfillmentrequest/v1`,
  POST_NONSORT_UNITS: `${config.planningApiUrl}/nodemgtxdcc/countnonsortableitems/v1`,
  POST_PLANNABLE_UNITS_COUNT: `${config.planningApiUrl}/nodemgtxdcc/countplannableunits/v1`,
  POST_PLANNABLE_UNIT_DETAILS: `${config.planningApiUrl}/nodemgtxdcc/plannableunitdetails/v1`,
  PLANDETAILS_PATH: `${config.planningApiUrl}/nodemgtxdcc/plandetails/v1`,

  /**
   * Weights and Measure service endpoints
   */
  POST_NON_SORT_FLAG: `${config.weightsMeasuresApiUrl}/nodemgtxdcc/nonsortflag/v1`,
  POUCHPARAMS_PATH: `${config.weightsMeasuresApiUrl}/nodemgtxdcc/pouchparams/v1`,

  /**
   * Message Reprocess service endpoints
   */
  REPROCESS_PATH: `${config.messageReprocessApiUrl}/nodemgtxdcc/processnames/v1`,
  FAILED_EVENTS_PATH: `${config.messageReprocessApiUrl}/nodemgtxdcc/failedevents/v1`,
  RETRY_EVENTS_PATH: `${config.messageReprocessApiUrl}/nodemgtxdcc/retryevents/v1`,
  BULK_FAILED_EVENTS_PATH: `${config.messageReprocessApiUrl}/nodemgtxdcc/failedeventcount/v1`,
  BULK_RETRY_EVENTS_PATH: `${config.messageReprocessApiUrl}/nodemgtxdcc/bulkretry/v1`,
};

export default API_INTERFACE_URI;
